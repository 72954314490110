import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageTypesEnum } from "../../pages/wp-chat/utils";
import { WhatsappMessage } from "../api/types/get-whatsapp-messages.types";
import { ConversationLeft, WhatsappContact } from "../api/types/get-whatsapp-contacts.types";

export type WPChatReducerType = Readonly<{
  rightContextMenu: RightContextMenuType;
  repliedTo: RepliedToType;
  isMobileChatView: boolean;
  activeMobileScreen: string;
  activeChat: WhatsappContact | null;
  newMessageSent: boolean;
  newMessageReceived: boolean;
  chatTimeLeft: number;
}>;

export type RepliedToType = {
  name: string;
  message: string;
  type: string;
  data: any | WhatsappMessage;
};

export type RightContextMenuType = Readonly<{
  reply: boolean;
  react: boolean;
  forward: boolean;
  copy: boolean;
  info: boolean;
  addTask: boolean;
  writeNote: boolean;
  delete: boolean;
  selectMessage: boolean;
}>;

export const initialRightContextMenu: RightContextMenuType = {
  reply: false,
  react: false,
  forward: false,
  copy: false,
  info: false,
  addTask: false,
  writeNote: false,
  delete: false,
  selectMessage: false
};

export const initialRepliedTo: RepliedToType = {
  name: "",
  message: "",
  type: MessageTypesEnum.TEXT || MessageTypesEnum.AUDIO || MessageTypesEnum.DOCUMENT || MessageTypesEnum.IMAGE || MessageTypesEnum.VIDEO,
  data: null
};

type ActiveMobileScreenType = { LEFT_SCREEN: string; MID_SCREEN: string; RIGHT_SCREEN: string };

export const EnumActiveMobileScreen: ActiveMobileScreenType = {
  LEFT_SCREEN: "LEFT",
  MID_SCREEN: "MID",
  RIGHT_SCREEN: "RIGHT"
};

export const initialState: WPChatReducerType = {
  rightContextMenu: { ...initialRightContextMenu },
  repliedTo: { ...initialRepliedTo },
  isMobileChatView: false,
  activeMobileScreen: EnumActiveMobileScreen.LEFT_SCREEN,
  activeChat: null,
  newMessageSent: false,
  newMessageReceived: false,
  chatTimeLeft: 0
};

export const wPChatReducerSlice = createSlice({
  name: "wpChatReducer",
  initialState,
  reducers: {
    setInitialRightContextMenuAction: (state) => {
      state.rightContextMenu = { ...initialRightContextMenu };
    },
    setUpdateRightContextMenuAction: (state, action: PayloadAction<RightContextMenuType>) => {
      state.rightContextMenu = action.payload;
    },
    setInitialRepliedToAction: (state) => {
      state.repliedTo = { ...initialRepliedTo };
    },
    setUpdateRepliedToAction: (state, action: PayloadAction<RepliedToType>) => {
      state.repliedTo = action.payload;
    },
    setIsMobileChatViewAction: (state, action: PayloadAction<boolean>) => {
      state.isMobileChatView = action.payload;
    },
    setActiveMobileScreenAction: (state, action: PayloadAction<string>) => {
      state.activeMobileScreen = action.payload;
    },
    setActiveChatAction: (state, action: PayloadAction<WhatsappContact | null>) => {
      state.activeChat = action.payload;
    },
    setNewMessageSentAction: (state, action: PayloadAction<boolean>) => {
      state.newMessageSent = action.payload;
    },
    setNewMessageReceivedAction: (state, action: PayloadAction<boolean>) => {
      state.newMessageReceived = action.payload;
    },
    setChatTimeLeftAction: (state, action: PayloadAction<number>) => {
      state.chatTimeLeft = action.payload;
    }
  }
});

export const {
  setInitialRightContextMenuAction,
  setUpdateRightContextMenuAction,
  setInitialRepliedToAction,
  setUpdateRepliedToAction,
  setIsMobileChatViewAction,
  setActiveMobileScreenAction,
  setActiveChatAction,
  setNewMessageSentAction,
  setNewMessageReceivedAction,
  setChatTimeLeftAction
} = wPChatReducerSlice.actions;

export const wpChatReducer = wPChatReducerSlice.reducer;
