import { getNumberWithCountryCode } from "../../../utils/remove-country-code";

import Pdf from "../../../assets/images/PDF.png";

import Audio from "../../../assets/images/Audio.png";
import FileIcon from "../../../assets/images/File.svg";
import CSVIcon from "../../../assets/images/csv.svg";
import { RightContextMenuType, setInitialRightContextMenuAction, setUpdateRightContextMenuAction } from "../../../store/reducer/wp-chat.reducer";
import { useEffect } from "react";
import { io } from "socket.io-client";
import dayjs from "dayjs";

export const orgSuperfoneNumber = (org: any) => {
  let a = [];
  a = getNumberWithCountryCode({
    number: org?.superfone_number || undefined,
    country: org?.country
  });

  return a;
};

export function getInitials(name: string): string {
  try {
    const nameParts = name?.split(" ");

    if (nameParts.length >= 2) {
      const firstInitial = nameParts[0].charAt(0).toUpperCase();
      const lastInitial = nameParts[1].charAt(0).toUpperCase();

      return `${firstInitial}${lastInitial}`;
    } else {
      // Handle cases where the input is just a single word
      return name.charAt(0).toUpperCase();
    }
  } catch (error) {
    return name;
  }
}

export function formatTime(dateString: string): string {
  const time = dateString ? dayjs(dateString).format("hh:mm a") : "";

  return time;
  const date = new Date(dateString);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes} ${ampm}`;
}

export const userName = (usersSync: any) => `${usersSync?.user?.first_name || ""} ${usersSync?.user?.last_name || ""}`.trim();

export const getMajorIcon = (fileType: string, file: File) => {
  const file_type = fileType?.toLowerCase() || "";
  if (file_type.includes("image")) {
    return URL.createObjectURL(file);
  } else if (file_type.includes("pdf")) {
    return Pdf;
  } else if (file_type.includes("audio")) {
    return Audio;
  } else if (file_type.includes("csv")) {
    return CSVIcon;
  } else {
    return FileIcon;
  }
};

export const getFileIcon = (extension: string) => {
  switch (extension) {
    case "pdf":
      return Pdf;
    case "csv":
      return CSVIcon;
    default:
      return FileIcon;
  }
};

export const handleRightContextMenuClick = (key: string, value: boolean, rightContextMenu: RightContextMenuType, dispatch: any) => {
  dispatch(setInitialRightContextMenuAction());
  dispatch(setUpdateRightContextMenuAction({ ...rightContextMenu, [key]: value }));
};

export const MessageTypesEnum = Object.freeze({
  TEXT: "text",
  AUDIO: "audio",
  DOCUMENT: "document",
  IMAGE: "image",
  VIDEO: "video"
});

/**
 *
 * @param ref reference of dom element
 * @param cb handles functionality when user click outside the referenced element
 */
export function useOutsideAlerter(ref: any, cb: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current?.contains?.(event.target)) {
        cb();
      }
    } // Bind the event listener
    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const emojiIcons: string[] = ["👍", "❤️", "😂", "😯", "😢", "🙏"];

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Get day and month name
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", { month: "long" }); // Full month name (e.g., August)

  // Return the formatted date in "DD Month" format
  return `${day} ${month}`;
}

export const SocketUrl = "https://prod-api.superfone.co.in/superfone/socket.io/";

export const getHoursRemaining = (targetTime: any) => {
  // Current time
  const now: any = new Date();

  // Target time
  targetTime = new Date(targetTime);

  // Calculate the difference in milliseconds
  const timeDifference = targetTime - now;

  // Convert the difference to hours
  const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

  console.log(`Hours left: ${hoursLeft}`);

  return hoursLeft;
};
