import Lite from "../../assets/images/lite.svg";
import Pro from "../../assets/images/pro.svg";
export enum old_plan_ids {
  biz = 18,
  lite = 19,
  pro = 20,
  wabaAdv = 12,
  standardV3 = 9,
  wabaV2 = 17,
  freeTier = 15,
  wabaBiz2 = 21,
  wabaLitev1 = 22,
  wabaCRMv1 = 16,
  crmTrial = 14,
  crmV1 = 13,
  wabaV1 = 10,
  standardCrmLegacy = 23,
  crmIntV1 = 11,
  sharedDemo = 24,
}

enum plan_ids {
  biz = 1017,
  lite = 1015,
  pro = 1016,
  bizWabaAdv = 1019,
  proWabaAdv = 1018,
  liteWabaAdv = 1020,
  bizUnlimited = 1023,
  liteUnlimited = 1021,
  proUnlimited = 1022,
  bizUnlimitedWabaAdv = 1025,
  liteUnlimitedWabaAdv = 1026,
  proUnlimitedWabaAdv = 1024,
}
const ProIconBig = <img className="w-[60px] h-5" alt="lite" src={Pro} />;

const LiteIconBig = <img className="w-[60px] h-5" alt="lite" src={Lite} />;

const ProIconSmall = <img className="w-[46px] h-[22px]" alt="pro" src={Pro} />;

const LiteIconSmall = (
  <img className="w-[46px] h-[22px]" alt="lite" src={Lite} />
);

const oldBizPlan = {
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  smallIcon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1079,
      name: "6 months",
      disabled: false,
      monthly: 343,
      text: {
        primary: "Free incoming & 3000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
    {
      id: 1078,
      name: "12 months",
      disabled: false,
      monthly: 400,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const oldLitePlan = {
  short_name: "Lite",
  name: "All-in-one",
  icon: LiteIconBig,
  smallIcon: LiteIconSmall,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: 1088,
      name: "6 months",
      disabled: true,
      monthly: 0,
      text: {
        primary: "",
        secondary: "",
      },
    },
    {
      id: 1080,
      name: "12 months",
      disabled: false,
      monthly: 427,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const oldProPlan = {
  short_name: "Pro",
  name: "All-in-one",
  icon: ProIconBig,
  smallIcon: ProIconSmall,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1083,
      name: "6 months",
      disabled: false,
      monthly: 999,
      text: {
        primary: "Free incoming & 3000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
    {
      id: 1082,
      name: "12 months",
      disabled: false,
      monthly: 856,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const v3BizPlan = {
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  smallIcon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1098,
      name: "6 months",
      disabled: false,
      monthly: 343,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 30 min/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1097,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 400,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "⭐ 1 hr extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3LitePlan = {
  short_name: "Lite",
  name: "All-in-one",
  icon: LiteIconBig,
  smallIcon: LiteIconSmall,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: 1094,
      name: "6 months",
      disabled: true,
      monthly: 0,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1093,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 427,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3ProPlan = {
  short_name: "Pro",
  name: "All-in-one",
  icon: ProIconBig,
  smallIcon: ProIconSmall,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1096,
      name: "6 months",
      disabled: false,
      monthly: 999,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1095,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 856,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3AdvBizPlan = {
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  smallIcon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1102,
      name: "6 months",
      disabled: false,
      monthly: 343,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 30 min/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1101,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 400,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "⭐ 1 hr extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3AdvLitePlan = {
  short_name: "Lite",
  name: "All-in-one",
  icon: LiteIconBig,
  smallIcon: LiteIconSmall,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: -1,
      name: "6 months",
      disabled: true,
      monthly: 0,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1103,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 427,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3AdvProPlan = {
  short_name: "Pro",
  name: "All-in-one",
  icon: ProIconBig,
  smallIcon: ProIconSmall,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1099,
      name: "6 months",
      disabled: false,
      monthly: 999,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1100,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 856,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedBizPlan = {
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  smallIcon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1109,
      name: "6 months",
      disabled: false,
      monthly: 343,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 30 min/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1108,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 400,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "⭐ 1 hr extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedLitePlan = {
  short_name: "Lite",
  name: "All-in-one",
  icon: LiteIconBig,
  smallIcon: LiteIconSmall,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: 1105,
      name: "6 months",
      disabled: true,
      monthly: 0,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1104,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 427,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedProPlan = {
  short_name: "Pro",
  name: "All-in-one",
  icon: ProIconBig,
  smallIcon: ProIconSmall,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1107,
      name: "6 months",
      disabled: false,
      monthly: 999,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1106,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 856,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedBizWabaAdvPlan = {
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  smallIcon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1112,
      name: "6 months",
      disabled: false,
      monthly: 343,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 30 min/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1113,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 400,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "⭐ 1 hr extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedLiteWabaAdvPlan = {
  short_name: "Lite",
  name: "All-in-one",
  icon: LiteIconBig,
  smallIcon: LiteIconSmall,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: -1,
      name: "6 months",
      disabled: true,
      monthly: 0,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1114,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 427,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const v3UnlimitedProWabaAdvPlan = {
  short_name: "Pro",
  name: "All-in-one",
  icon: ProIconBig,
  smallIcon: ProIconSmall,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1110,
      name: "6 months",
      disabled: false,
      monthly: 999,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1111,
      name: "12 months",
      bestDeal: true,
      disabled: false,
      monthly: 856,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

export const planConfig = {
  [old_plan_ids["biz"]]: oldBizPlan,
  [old_plan_ids["lite"]]: oldLitePlan,
  [old_plan_ids["pro"]]: oldProPlan,
  [old_plan_ids["wabaAdv"]]: oldProPlan,
  [plan_ids["biz"]]: v3BizPlan,
  [plan_ids["lite"]]: v3LitePlan,
  [plan_ids["pro"]]: v3ProPlan,
  [plan_ids["bizWabaAdv"]]: v3AdvBizPlan,
  [plan_ids["liteWabaAdv"]]: v3AdvLitePlan,
  [plan_ids["proWabaAdv"]]: v3AdvProPlan,
  [plan_ids["bizUnlimited"]]: v3UnlimitedBizPlan,
  [plan_ids["liteUnlimited"]]: v3UnlimitedLitePlan,
  [plan_ids["proUnlimited"]]: v3UnlimitedProPlan,
  [plan_ids["bizUnlimitedWabaAdv"]]: v3UnlimitedBizWabaAdvPlan,
  [plan_ids["liteUnlimitedWabaAdv"]]: v3UnlimitedLiteWabaAdvPlan,
  [plan_ids["proUnlimitedWabaAdv"]]: v3UnlimitedProWabaAdvPlan,
};

export const getPlanLevel = (planId?: number) => {
  const isV3BizAdv = planId === plan_ids["bizWabaAdv"];
  const isV3Biz = planId === plan_ids["biz"];
  const isV3LiteAdv = planId === plan_ids["liteWabaAdv"];
  const isV3Lite = planId === plan_ids["lite"];
  const isV3ProAdv = planId === plan_ids["proWabaAdv"];
  const isV3Adv = isV3BizAdv || isV3LiteAdv || isV3ProAdv;
  const isV3Pro = planId === plan_ids["pro"];
  const isV3 =
    planId === plan_ids["biz"] ||
    planId === plan_ids["lite"] ||
    planId === plan_ids["pro"];
  const isUnlimitedBiz = planId === plan_ids["bizUnlimited"];
  const isUnlimitedLite = planId === plan_ids["liteUnlimited"];
  const isUnlimitedPro = planId === plan_ids["proUnlimited"];

  const isUnlimitedBizWabaAdv = planId === plan_ids["bizUnlimitedWabaAdv"];
  const isUnlimitedLiteWabaAdv = planId === plan_ids["liteUnlimitedWabaAdv"];
  const isUnlimitedProWabaAdv = planId === plan_ids["proUnlimitedWabaAdv"];
  const isNewV3 = isV3Biz || isV3Lite || isV3Pro || isV3Adv;
  const isBiz = isV3Biz || isUnlimitedBiz || isV3BizAdv;
  const isOldBiz = planId === old_plan_ids["biz"];
  const isLite = isV3Lite || isUnlimitedLite || isV3LiteAdv;
  const isOldLite = planId === old_plan_ids["lite"];
  const isPro = isV3Pro || isUnlimitedPro || isV3ProAdv;
  const isV1Adv = planId === old_plan_ids["wabaAdv"];
  const isV1Pro = planId === old_plan_ids["pro"];
  const isOldPro = isV1Pro || isV1Adv;
  const isNew = isBiz || isLite || isPro;
  const isV1 = isOldBiz || isOldLite || isOldPro;

  const isBizOldOrNew =
    isBiz || isOldBiz || isUnlimitedBiz || isUnlimitedBizWabaAdv;
  const isLiteOldOrNew =
    isLite || isOldLite || isUnlimitedLite || isUnlimitedLiteWabaAdv;
  const isProOldOrNew =
    isPro || isOldPro || isUnlimitedPro || isUnlimitedProWabaAdv;
  const isBizLiteOrProOldOrNew =
    isBizOldOrNew || isLiteOldOrNew || isProOldOrNew;
  const isNonStandardPlan = !isBizLiteOrProOldOrNew;

  const isUnlimited =
    isUnlimitedBiz ||
    isUnlimitedLite ||
    isUnlimitedPro ||
    isUnlimitedBizWabaAdv ||
    isUnlimitedLiteWabaAdv ||
    isUnlimitedProWabaAdv;

  const extraTeamMember = isUnlimited
    ? addonCharges.extraTeamMember.unlimited
    : addonCharges.extraTeamMember.v3;

  const isV1OrNonStandard = !isNewV3 && !isUnlimited;

  const isDailyOrUnlimited = isV3 || isV3Adv || isUnlimited;

  return {
    isBiz,
    isLite,
    isPro,
    isV1,
    isV1Pro,
    isV1Adv,
    isV3,
    isNew,
    isNewV3,
    isOldBiz,
    isOldLite,
    isOldPro,
    isNonStandardPlan,
    isBizOldOrNew,
    isLiteOldOrNew,
    isProOldOrNew,
    isBizLiteOrProOldOrNew,
    isUnlimitedBiz,
    isUnlimitedLite,
    isUnlimitedPro,
    isUnlimited,
    extraTeamMember,
    isV1OrNonStandard,
    isV3Biz,
    isV3Lite,
    isV3Pro,
    isV3BizAdv,
    isV3LiteAdv,
    isV3ProAdv,
    isDailyOrUnlimited,
    isUnlimitedBizWabaAdv,
    isUnlimitedLiteWabaAdv,
    isUnlimitedProWabaAdv,
  };
};

export type GetPlanLevel = typeof getPlanLevel;

export const almost_full_limit = 0.8;

export const businessPlanFeatures = [
  "30 days Call Recording",
  "Business Caller Tune",
  "Reports",
];

export const basicCRMFeatures = [
  "1 lead source integration",
  "Unlimited leads, 500 storage limit",
  "Fresh lead alert, tasks, reminders",
  "Web dashboard (owners only)",
  "Data protection & number masking",
];

export const advancedCRMFeatures = [
  "Multiple lead source integration",
  "Unlimited leads, 10000 storage limit",
  "Agent Assignment",
  "Web dashboard (all users)",
  "Unlimited tags, lead stages, message templates",
  "Advanced Reports",
  "Data protection & number masking",
];

export const enterpriseFeatures = [
  "Manage multiple numbers",
  "Webhooks & Integrations",
  "Custom lead integrations",
  "Automations",
  "Initiate call from web dashboard",
  "AI Call summary coming soon",
];
export const sharedDemoOrgs =
  process.env.REACT_APP_API_HOST ===
  "https://prod-api.superfone.co.in/superfone"
    ? [
        150877, 150878, 150879, 150880, 150881, 150882, 150883, 150884, 150885,
        150886, 150887, 150888, 150889, 150890, 150891, 150892, 150893, 150894,
        150895, 150896,
      ]
    : [
        115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128,
        129, 130, 131, 132, 133, 134,
      ];

export const addonCharges = {
  base: {
    v3: {
      halfYearly: {
        biz: 599,
        lite: 699,
        pro: 1099,
      },
      yearly: {
        biz: 499,
        lite: 599,
        pro: 999,
      },
    },
    unlimited: {
      halfYearly: {
        biz: 999,
        lite: 1099,
        pro: 1499,
      },
      yearly: {
        biz: 799,
        lite: 899,
        pro: 1299,
      },
    },
  },
  extraTeamMember: {
    v3: {
      halfYearly: {
        biz: 99,
        lite: 149,
        pro: 249,
      },
      yearly: {
        biz: 99,
        lite: 149,
        pro: 249,
      },
    },
    unlimited: {
      halfYearly: {
        biz: 333,
        lite: 366,
        pro: 499,
      },
      yearly: {
        biz: 266,
        lite: 299,
        pro: 433,
      },
    },
  },
  storage: {
    halfYearly: 299,
    yearly: 499,
  },
};

export const getUnlimitedProId = () => {
  return plan_ids["proUnlimited"];
};
